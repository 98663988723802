import {compose, equals, prop} from 'ramda'
import {GetterTree, MutationTree, ActionTree} from 'vuex'
import {ClassShallowProps, RDFS, ShallowClass} from "~/shared";
import {get as getRoot} from "@/api/root";
import {getClasses} from "@/api/classes";
import {getLang} from "@/utils/lang";
import {Endpoint} from "@/types";
import {equalsById} from "@/functions";

let lang
let endpoint: Endpoint

class State {
  classes: ShallowClass[] = []
  root: ShallowClass = null
}

const getters: GetterTree<State, any> = {}

const mutations: MutationTree<State> = {
  setRoot(state, payload) { state.root = payload},
  setClasses(state, payload) {state.classes = payload},
  addClass(state, payload: ShallowClass) {
    const indexOfClass = state.classes.findIndex(equalsById(payload))
    if (!payload.props) return
    if (indexOfClass > -1) {
      this.commit('classes/patchClass', payload)
    }
    else {
      state.classes.push(payload)
    }
  },
  patchClass(state, payload: ShallowClass) {
    const actualClass = state.classes.find(({id}) => id === payload.id)
    if (actualClass) {
      if (payload.access) {
        actualClass.access = payload.access
      }
      if (payload.operations) {
        actualClass.operations = payload.operations
      }
      if (payload.props) {
        if (actualClass.props === undefined || actualClass.props === null)
          actualClass.props = {} as ClassShallowProps
        actualClass.props = Object.assign(actualClass.props, payload.props)
        if (actualClass.name !== payload.name) {
          actualClass.name = payload.name
          state.classes.forEach((item) => {
            item.props[RDFS.subClassOf].forEach((parent) => {
              if (parent.id === payload.id) {
                parent.name = payload.name
              }
            })
          })
        }
      }
    }
  },
  removeClass(state, id: string) {
    const indexOfClass = state.classes.findIndex((item) => item.id === id)
    if (indexOfClass > -1) {
      state.classes.splice(indexOfClass, 1)
    }
    state.classes.forEach((item) => {
      const index = item.props[RDFS.subClassOf].findIndex(compose(equals(id), prop(id)))
      if (index > -1) {
        item.props[RDFS.subClassOf].splice(index, 1)
      }
    })
  }
}

const actions: ActionTree<State, any> = {
  async init(context) {
    lang = getLang()
    endpoint = context.rootState.endpoints.endpoint

    context.commit('setRoot', (await getRoot(endpoint.id, lang)).data)
    context.commit('setClasses', (await getClasses(endpoint.id, lang, context.rootState.ontologyVersions.viewVersion)).data)
  }
}

export default {
  namespaced: true,
  state: new State(),
  mutations: mutations,
  actions: actions,
  getters: getters,
}
