import BaseError from './BaseError'

export default class LicenseKeysMismatchError extends BaseError<{}> {
  httpCode = 500

  constructor(params: {}) {
    super(params, 'LicenseKeysMismatchError');
  }

  getMessage(): string {
    return 'LicenseKeysMismatch'
  }
}
