import BaseError from './BaseError'

export default class ProposedToConfirmError extends BaseError<{}> {
	httpCode = 202


	constructor(params: {}) {
		super(params, 'ProposedToConfirmError');
	}

	getMessage(): string {
		return 'Changes are saved, but required administrator`s confirmation'
	}
}
