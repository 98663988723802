import {Component, Mixins, Watch} from 'vue-property-decorator'
import type {Api} from '~/shared'
import {AppConfig, NotAuthenticatedError} from '~/shared'

import {apiClient} from '@/api/client'
import {InjectReactive, Provide, ProvideReactive} from '@/vue-extensions'
import {INJECTIONS} from '@/injections'
import {UserCredentials} from '@/types'
import {AuthState, Status, Language} from '@/constants'
import {login, logout} from '@/api/auth'
import HandlesErrors from '@/providers/HandlesErrors'
import I18n from '@/i18n.mixin'

type User = Api.User

@Component({
  lang: {
    [Language.ru]: {
      relogin:
        'У пользователя отсутствует доступ к данному приложению. \nОбратитесь к администратору. \nАвторизоваться в другой учетной записи?',
      invalidMappingKC: 'Неверно настроен клиент в KeyCloak. \nОбратитесь к администратору. \nАвторизоваться в другой учетной записи?'
    },
    [Language.en]: {
      relogin:
        'The user does not have access to this application. \nPlease contact your administrator. \nSign in with a different account?',
      invalidMappingKC: 'The client in KeyCloak is not configured correctly. \nPlease contact your administrator. \nAre you logging in with another account?'
    },
  },
})
export default class AuthProvider extends Mixins(HandlesErrors, I18n) {
  @InjectReactive(INJECTIONS.UI_LANGUAGE.CURRENT) uiLang: Language
  @InjectReactive(INJECTIONS.CONFIG.STATUS) readonly configStatus: Status
  private config: AppConfig

  @ProvideReactive(INJECTIONS.AUTH.USER) user: User = null
  @ProvideReactive(INJECTIONS.AUTH.STATE) state: AuthState = AuthState.idle

  private clientInterceptor: number

  created() {
    this.enhanceApiClient()
    if (this.configStatus === Status.success) {
      this.checkAuth()
    }
  }

  @Watch('configStatus') onConfigStatusChange(newStatus: Status) {
    if (newStatus === Status.success) {
      this.checkAuth()
    }
  }

  beforeDestroy() {
    this.removeApiClientEnhancement()
  }

  async checkAuth() {
    this.state = AuthState.checkupPending
    this.config = this.$store.state.config.config
    try {
      // const { user, access } = await check()
      await this.$store.dispatch('auth/check')
      const user = this.$store.state.auth.user
      const access = this.$store.state.auth.access
      const hasMappingKC = this.$store.state.auth.user.originator.hasMappingKC
      if (!hasMappingKC) {
        if (confirm(this.lang.invalidMappingKC)) {
          await this.logout(true)
        }
      }
      else if (!access) {
        if (confirm(this.lang.relogin)) {
          await this.logout(true)
        }
      }
      else {
        this.state = AuthState.authenticated
        this.user = user
        this.$store.dispatch('license/getLicense')
      }
    } catch (e) {
      console.log(e)
      this.state = AuthState.failed
      if (!(e instanceof NotAuthenticatedError)) {
        this.handleError(e)
      }
    }
  }

  @Provide(INJECTIONS.AUTH.LOGOUT)
  async logout(force?: boolean) {
    if (!force && this.state !== AuthState.authenticated) {
      throw new Error('Not authenticated')
    }
    await logout()
    this.state = AuthState.loggedOut
  }

  @Provide(INJECTIONS.AUTH.LOGIN)
  async login(credentials: UserCredentials) {
    this.user = await login(credentials)
    this.state = AuthState.authenticated
  }

  @Watch('state', {immediate: true})
  OnStateChange(val: AuthState) {
    if (val === AuthState.authenticated) {
      apiClient.patch('uiLang/' + this.uiLang)
    }
  }

  private enhanceApiClient() {
    this.clientInterceptor = apiClient.interceptors.response.use(undefined, (error) => {
      if (error instanceof NotAuthenticatedError) {
        this.state = AuthState.failed
      }
      throw error
    })
  }

  private removeApiClientEnhancement() {
    if (typeof this.clientInterceptor !== 'undefined') {
      apiClient.interceptors.response.eject(this.clientInterceptor)
    }
  }

  get isSso() {
    return this.config && this.config.auth.method === 'sso'
  }

  @Watch('state') onStateChange(newState: AuthState) {
    if (this.isSso && (newState === AuthState.failed || newState === AuthState.loggedOut)) {
      window.location.href = this.config.auth.oauthLoginUrl
    }
  }

  render() {
    return this.$slots.default
  }
}
