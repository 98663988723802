import BaseError from './BaseError'

export default class UnknownServerError<T extends Error> extends BaseError<{
	previous: T
	requestId?: string
}> {
	httpCode = 500


	constructor(params: { previous: T; requestId?: string }) {
		super(params, 'UnknownServerError');
	}

	getMessage(): string {
		let v = 'Unknown server error: '
		if (this.params.previous.name !== 'Error') {
			v += `${this.params.previous.name}: `
		}
		v += `${this.params.previous.message}.`
		if (this.params.requestId) {
			v += ` Request id: ${this.params.requestId}`
		}
		return v
	}
}
