import { InputType } from './constants'
import { TranslatableLabel } from './types'
import { ARCHIGRAPH, OWL, XSD, ReferenceValue } from './'
import { UOM } from './mdm-types'

// свойство multiple необходимо для большей точности проверок на этапе компиляции
type ArrayProp<T> = T extends any[] ? { multiple: true } : { multiple?: false }

export type CommonFieldSchema<T> = ArrayProp<T> & {
	inputType?: InputType
	label?: string | TranslatableLabel
	minCar: number | null
	maxCar: number | null
	isAdditional?: boolean
	readOnly?: boolean
}

export type LiteralFieldSchema<T, D extends XSD> = CommonFieldSchema<T> & {
	literal: D
	hasQuantityKind?: string
	nonMultilang?: boolean
}

export type InferLiteralType<F> = F extends LiteralFieldSchema<any, infer L> ? L : never

export type ReferenceType =
	| OWL.Class
	| OWL.NamedIndividual
	| OWL.ObjectProperty
	| OWL.DatatypeProperty
	| ARCHIGRAPH.type

export type BaseReference<T extends ReferenceType> = {
	type: T
}

export type IndividualReference = BaseReference<OWL.NamedIndividual> & {
	class: string
}

export type TypeReference =
	| BaseReference<OWL.Class>
	| BaseReference<OWL.DatatypeProperty>
	| BaseReference<OWL.ObjectProperty>
	| BaseReference<OWL.NamedIndividual>
	| BaseReference<ARCHIGRAPH.type>
	| IndividualReference

export const isIndividualReference = (reference: TypeReference): reference is IndividualReference =>
	'class' in reference && typeof reference.class !== 'undefined'

export type ReferenceFieldSchema<T, R extends TypeReference[]> = CommonFieldSchema<T> & {
	reference: R
}

export type FieldSchema<T> = T extends ReferenceValue | ReferenceValue[]
	? ReferenceFieldSchema<T, TypeReference[]>
	: LiteralFieldSchema<T, XSD>

export type FieldValue<T> = T extends FieldSchema<infer O> ? O : never

export type LiteralValue = null | number | boolean | string

export type UomLiteralValue = { value: LiteralValue } & UOM

export type AllowedLiteralValue = LiteralValue | UomLiteralValue

export type AllowedValue = AllowedLiteralValue | ReferenceValue

export type AllowedObject = Record<string, AllowedValue | AllowedValue[]>

export type Schema<T extends AllowedObject> = {
	[K in keyof T]?: FieldSchema<T[K]>
}

export const isLiteralFieldSchema = (
	value: FieldSchema<any>
): value is LiteralFieldSchema<any, any> =>
	'literal' in value && typeof value.literal !== 'undefined'

export const isReferenceFieldSchema = (
	value: FieldSchema<any>
): value is ReferenceFieldSchema<any, any> =>
	'reference' in value && typeof value.reference !== 'undefined'

export type GetFieldValueType<T> = T extends FieldSchema<infer V> ? V : never
