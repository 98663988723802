import {GetterTree, MutationTree, ActionTree} from 'vuex'
import {apiClient} from "@/api/client";
import {Constant} from "~/shared";
import {getEndpoint} from "@/utils/endpoints";

class State {
  constants: Constant = null
}

const getters: GetterTree<State, any> = {
  constants: (state) => state.constants
}

const mutations: MutationTree<State> = {
  setConstants(state, payload) {
    state.constants = payload
  }
}

const actions: ActionTree<State, any> = {
  async init(context) {
    const endpointId = await getEndpoint(context)
    context.commit('setConstants', (await apiClient.get(`/endpoints/${endpointId}/constants`)).data)
  },
}

export default {
  namespaced: true,
  state: new State(),
  mutations: mutations,
  actions: actions,
  getters: getters,
}
