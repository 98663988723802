import BaseError from './BaseError'

export default class WrongImportHeadersError extends BaseError<{
	expectedHeaders: string[]
}> {
	httpCode = 500


	constructor(params: { expectedHeaders: string[] }) {
		super(params, 'WrongImportHeadersError');
	}

	getMessage(): string {
		return 'Wrong import headers. Expected ' + this.params.expectedHeaders.join(', ')
	}
}
