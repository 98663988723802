import { ModelTypes } from '~/shared'

import { apiClient } from '@/api/client'

export const get = async (endpoint: string, language?: string): Promise<ModelTypes.Prefix[]> => {
	let url = '/endpoints/' + endpoint + '/prefixes'
	if (language) {
		url = url + '?language=' + language
	}
	const response = await apiClient.get(url)
	return response.data
}
