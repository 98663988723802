import { BaseError, ReferenceValue } from '~/shared'

export class ClassHasNotAttributeError extends BaseError<{
	id: string
	type: string
	_class: ReferenceValue
}> {
	httpCode = 400

	getMessage(): string {
		//@ts-ignore
		return `Class ${this.params._class.id} does not have attribute ${this.params.id} with type ${this.params.type}`
	}
}
