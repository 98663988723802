import BaseError from './BaseError'

export default class AuthInvalidCredentialsError extends BaseError<void> {
	httpCode = 409


	constructor(params: void) {
		super(params, 'AuthInvalidCredentialsError');
	}

	getMessage(): string {
		return 'Invalid auth credentials!'
	}
}
