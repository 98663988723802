<template>
	<div class="cross" @click="$emit('click')">
		<SvgClose :height="20" :width="20"/>
	</div>
</template>

<script>
import SvgClose from '@/components/Svg/Close'

export default {
	name: 'Cross',
	components: {
		SvgClose
	}
}
</script>

<style lang="scss" scoped>
.cross {
	cursor: pointer;
	height: 23px;
	width: 23px;

	&:hover{
		:deep(svg) line {
			stroke: var(--button);
		}
	}
}
</style>
