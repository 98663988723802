import BaseError from './BaseError'

export default class UnknownLicenseError extends BaseError<{}> {
  httpCode = 500

  constructor(params: {}) {
    super(params, 'UnknownLicenseError');
  }

  getMessage(): string {
    return 'UnknownLicenseError'
  }
}
