import BaseError from './BaseError'

export default class LicenseInvalidKeyError extends BaseError<{}> {
  httpCode = 500


  constructor(params: {}) {
    super(params, 'LicenseInvalidKeyError');
  }

  getMessage(): string {
    return 'LicenseInvalidKey'
  }
}
