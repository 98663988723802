import { errorTypes as sharedErrorTypes } from '~/shared'

import { ClassHasNotAttributeError } from './errors/class-has-not-attribute-error'
import { ClassNotSelectedError } from '@/core/error-handling/errors/class-not-selected-error'
import { NetworkError } from '@/core/error-handling/errors/network-error'
import { RejectedByUserError } from '@/core/error-handling/errors/rejected-by-user-error'
import { SocketError } from '@/core/error-handling/errors/socket-error'

export const errorTypes = {
	...sharedErrorTypes,
	ClassHasNotAttributeError,
	ClassNotSelectedError,
	NetworkError,
	RejectedByUserError,
	SocketError,
}

export {
	ClassHasNotAttributeError,
	ClassNotSelectedError,
	NetworkError,
	RejectedByUserError,
	SocketError,
}
