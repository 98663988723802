import BaseError from './BaseError'

export default class LicenseInvalidAppIdError extends BaseError<{}> {
  httpCode = 500


  constructor(params: {}) {
    super(params, 'LicenseInvalidAppIdError');
  }

  getMessage(): string {
    return 'LicenseInvalidAppId'
  }
}
