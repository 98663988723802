import { Api } from '~/shared'

import { apiClient } from '@/api/client'
import { ShallowClass } from '~/shared/new-types'

export const get = async (
	endpoint: string,
	language?: string
): Promise<Api.Response<ShallowClass>> => {
	let url = '/endpoints/' + endpoint + '/root'
	if (language) {
		url = url + '?language=' + language
	}
	const response = await apiClient.get(url)
	return response.data
}
