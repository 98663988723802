import {Language} from "@/constants";

export const keyErrors = {
  SelectRoute: 'SelectRoute',
  RouteCreated: 'RouteCreated',
  CopyToBuffer: 'CopyToBuffer',
  ObjectAlreadyExists: 'ObjectAlreadyExists',
  ImportProcessHasError: 'ImportProcessHasError',
  ExportProcessHasError: 'ExportProcessHasError',
  ImportHasError: 'ImportHasError',
  NoMatchVersionByCode: 'NoMatchVersionByCode',
  NoVersionInfo: 'NoVersionInfo',
  NoAccessError: 'NoAccessError',
  LicenseTechSupportExpireError: 'LicenseTechSupportExpireError',
  LicenseTechSupportDeadlineError: 'LicenseTechSupportDeadlineError',
  LicenseNoResponseKeyError: 'LicenseNoResponseKeyError',
  LicenseKeysMismatchError: 'LicenseKeysMismatchError',
  LicenseInvalidKeyError: 'LicenseInvalidKeyError',
  LicenseInvalidAppIdError: 'LicenseInvalidAppIdError',
  LicenseKeysEqualsError: 'LicenseKeysEqualsError',
  UnknownLicenseError: 'UnknownLicenseError',
  BeforeConfirmObjectNotExists: 'BeforeConfirmObjectNotExists',
  IgnoredClassesAddOk: 'IgnoredClassesAddOk',
  IgnoredClassesClearOk: 'IgnoredClassesClearOk',
  BuilderFormAdded: 'BuilderFormAdded',
  BuilderFormEdited: 'BuilderFormEdited',

  OperationSuccess: 'OperationSuccess',
  OperationError: 'OperationError',
  OperationInProgress: 'OperationInProgress',
  UnknownServerError: 'UnknownServerError',
  ClassDoesNotExistsError: 'ClassDoesNotExistsError',
  ObjectDoesNotExistError: 'ObjectDoesNotExistError',
  ClassHasNotAttributeError: 'ClassHasNotAttributeError',
  ClassNotSelectedError: 'ClassNotSelectedError',
  NotAuthenticatedError: 'NotAuthenticatedError',
  AuthInvalidCredentialsError: 'AuthInvalidCredentialsError',
  CacheBuildError: 'CacheBuildError',
  NetworkError: 'NetworkError',
  FeatureNotSupportedError: 'FeatureNotSupportedError',
  RejectedByUserError: 'RejectedByUserError',
  AttributeIsNotApplicableForClassError: 'AttributeIsNotApplicableForClassError',
  ProposedToConfirmError: 'ProposedToConfirmError',
  ClassAlreadyExistsError: 'ClassAlreadyExistsError',
  AttributeAlreadyExistsError: 'AttributeAlreadyExistsError',
  MdmError: 'MdmError',
  BackingServiceUnavailableError: 'BackingServiceUnavailableError',
  SocketError: 'SocketError',
  WrongImportHeadersError: 'WrongImportHeadersError',
  UndefinedMdmError: 'UndefinedMdmError',
  MdmTimeoutError: 'MdmTimeoutError',
  NoPrefixesError: 'NoPrefixesError',
  ComparisonVersionError: 'ComparisonVersionError'
}

export default {

  [keyErrors.SelectRoute]: {
    [Language.ru]: {
      title: 'Недостаточно прав',
      content: 'Выберите маршрут согласования',
      copy: false
    },
    [Language.en]: {
      title: 'Not enough rights',
      content: 'Choose an approval route',
      copy: false
    },
  },
  [keyErrors.RouteCreated]: {
    [Language.ru]: {
      title: 'Недостаточно прав',
      content: 'Cоздан маршрут для согласования',
      copy: false
    },
    [Language.en]: {
      title: 'Not enough rights',
      content: 'Route for approval created',
      copy: false
    },
  },
  [keyErrors.NoVersionInfo]: {
    [Language.ru]: {
      title: 'Ошибка определения версии',
      content: 'Нет информации о сохранённой версии модели от МДМ',
      copy: false
    },
    [Language.en]: {
      title: 'Version detection error',
      content: 'There is no information about the saved version of the model from MDM',
      copy: false
    },
  },
  [keyErrors.NoMatchVersionByCode]: {
    [Language.ru]: {
      title: 'Ошибка определения версии',
      content: code => `Код версии от МДМ (${code}) не найден в списке версий`,
      copy: true
    },
    [Language.en]: {
      title: 'Version detection error',
      content: code => `The version code from MDM (${code}) was not found in the version list`,
      copy: true
    },
  },
  [keyErrors.CopyToBuffer]: {
    [Language.ru]: {
      title: 'Копирование в буфер',
      content: (mes) => mes,
      copy: false
    },
    [Language.en]: {
      title: 'Buffer copy',
      content: (mes) => mes,
      copy: false
    },
  },
  [keyErrors.ObjectAlreadyExists]: {
    [Language.ru]: {
      title: 'Задайте другой идентификатор',
      content: 'Идентификатор уже используется другой сущностью',
      copy: false
    },
    [Language.en]: {
      title: 'Set a different Identifier',
      content: 'Identifier is already used by other entity',
      copy: false
    },
  },
  [keyErrors.ImportProcessHasError]: {
    [Language.ru]: {
      title: 'Ошибка процесса импорта',
      content: (mes) => mes,
      copy: true
    },
    [Language.en]: {
      title: 'Import process error',
      content: (mes) => mes,
      copy: true
    },
  },
  [keyErrors.ExportProcessHasError]: {
    [Language.ru]: {
      title: 'Ошибка процесса экспорта',
      content: (mes) => mes,
      copy: true
    },
    [Language.en]: {
      title: 'Export process error',
      content: (mes) => mes,
      copy: true
    },
  },
  [keyErrors.ImportHasError]: {
    [Language.ru]: {
      title: 'Во время импорта возникла ошибка(и)',
      content: 'Откройте скачанный файл с результатами',
      copy: false
    },
    [Language.en]: {
      title: 'Error(s) occurred during import',
      content: 'Open the downloaded file with the results',
      copy: false
    },
  },
  [keyErrors.LicenseTechSupportExpireError]: {
    [Language.ru]: {
      title: 'Проверка лицензии',
      content: 'Срок действия тех. поддержки скоро закончится. Обратитесь в компанию "ТриниДата".',
      copy: false
    },
    [Language.en]: {
      title: 'License verification',
      content: 'Tech support will expire. Contact "TriniData".',
      copy: false
    },
  },
  [keyErrors.LicenseTechSupportDeadlineError]: {
    [Language.ru]: {
      title: 'Проверка лицензии',
      content: 'Срок тех. поддержки закончился. Обратитесь в компанию "ТриниДата".',
      copy: false
    },
    [Language.en]: {
      title: 'License verification',
      content: 'The support period has expired. Contact "TriniData".',
      copy: false
    },
  },
  [keyErrors.LicenseNoResponseKeyError]: {
    [Language.ru]: {
      title: 'Проверка лицензии',
      content: 'Отсутствует ключ-ответ лицензии. Обратитесь в компанию "ТриниДата".',
      copy: false
    },
    [Language.en]: {
      title: 'License verification',
      content: 'No license response key. Contact "TriniData".',
      copy: false
    },
  },
  [keyErrors.LicenseKeysMismatchError]: {
    [Language.ru]: {
      title: 'Проверка лицензии',
      content: 'Ключ-ответ не соответствует ключ-запросу. Обратитесь в компанию "ТриниДата".',
      copy: false
    },
    [Language.en]: {
      title: 'License verification',
      content: 'Response key does not match the request key. Contact "TriniData".',
      copy: false
    },
  },
  [keyErrors.LicenseInvalidKeyError]: {
    [Language.ru]: {
      title: 'Проверка лицензии',
      content:
        'Один из ключей не содержит одно или несколько полей. Обратитесь в компанию "ТриниДата".',
      copy: false

    },
    [Language.en]: {
      title: 'License verification',
      content: 'One of the keys does not contain one or more fields. Contact "TriniData".',
      copy: false
    },
  },
  [keyErrors.LicenseInvalidAppIdError]: {
    [Language.ru]: {
      title: 'Проверка лицензии',
      content: 'Ключ-запрос не соответствует ПО. Обратитесь в компанию "ТриниДата".',
      copy: false
    },
    [Language.en]: {
      title: 'License verification',
      content: 'Request key does not match the software. Contact "TriniData".',
      copy: false
    },
  },
  [keyErrors.LicenseKeysEqualsError]: {
    [Language.ru]: {
      title: 'Проверка лицензии',
      content: 'Ключ-запрос не может совпадать с ключ-ответом. Обратитесь в компанию "ТриниДата".',
      copy: false
    },
    [Language.en]: {
      title: 'License verification',
      content: 'The request key cannot match the response key. Contact "TriniData".',
      copy: false
    },
  },
  [keyErrors.UnknownLicenseError]: {
    [Language.ru]: {
      title: 'Проверка лицензии',
      content: 'Неизвестная ошибка проверки лицензии. Обратитесь в компанию "ТриниДата".',
      copy: false
    },
    [Language.en]: {
      title: 'License verification',
      content: 'Unknown license verification error. Contact "TriniData".',
      copy: false
    },
  },
  [keyErrors.BeforeConfirmObjectNotExists]: {
    [Language.ru]: {
      title: '',
      content: (id) => `До подтверждения предлагаемых изменений в Маршруте согласования объект ${id} не существует`,
      copy: false
    },
    [Language.en]: {
      title: '',
      content: (id) => `Until the proposed changes are confirmed in Approval route, the ${id} object does not exist`,
      copy: false
    },
  },
  [keyErrors.IgnoredClassesAddOk]: {
    [Language.ru]: {
      title: '',
      content: () => 'Список классов для скрытия успешно обновлён.',
      copy: false
    },
    [Language.en]: {
      title: '',
      content: () => 'The list of classes to hide has been successfully updated.',
      copy: false
    },
  },
  [keyErrors.IgnoredClassesClearOk]: {
    [Language.ru]: {
      title: '',
      content: () => 'Список классов для скрытия очищен.',
      copy: false
    },
    [Language.en]: {
      title: '',
      content: () => 'The list of classes to hide has been cleared.',
      copy: false
    },
  },
  [keyErrors.BuilderFormAdded]: {
    [Language.ru]: {
      title: 'Конструктор форм импорта/экспорта',
      content: (arg) => `Форма "${arg}" добавлена`,
      copy: false
    },
    [Language.en]: {
      title: 'Import/export form builder',
      content: (arg) => `Form "${arg}" has been added`,
      copy: false
    },
  },
  [keyErrors.BuilderFormEdited]: {
    [Language.ru]: {
      title: 'Конструктор форм импорта/экспорта',
      content: (arg) => `Форма "${arg}" обновлена`,
      copy: false
    },
    [Language.en]: {
      title: 'Import/export form builder',
      content: (arg) => `Form "${arg}" has been edited`,
      copy: false
    },
  },


  [keyErrors.OperationSuccess]: {
    [Language.ru]: {
      content: 'Операция успешно выполнена',
      copy: false
    },
    [Language.en]: {
      content: 'The operation was successful',
      copy: false
    },
  },
  [keyErrors.OperationInProgress]: {
    [Language.ru]: {
      content: 'Операция выполняется',
      copy: false
    },
    [Language.en]: {
      content: 'Operation in progress',
      copy: false
    },
  },
  [keyErrors.OperationError]: {
    [Language.ru]: {
      title: 'Операция не выполнена.',
      content: (reason) => `Причина: ${reason}.`,
      copy: true
    },
    [Language.en]: {
      title: 'Operation failed.',
      content: (reason) => `Reason: ${reason}.`,
      copy: true
    },
  },
  [keyErrors.ClassDoesNotExistsError]: {
    [Language.ru]: {
      title: '',
      content: (params) => `Класс ${params.id} не существует`,
      copy: false
    },
    [Language.en]: {
      title: '',
      content: (params) => `Class ${params.id} does not exist`,
      copy: false
    },
  },
  [keyErrors.ObjectDoesNotExistError]: {
    [Language.ru]: {
      title: '',
      content: (params) => `Объект ${params.id} не существует`,
      copy: false
    },
    [Language.en]: {
      title: '',
      content: (params) => `Object ${params.id} does not exist`,
      copy: false
    },
  },
  [keyErrors.ClassHasNotAttributeError]: {
    [Language.ru]: {
      title: '',
      content: (params) => `Класс ${params._class.name}(${params._class.id}) не имеет атрибута ${params.id}`,
      copy: false
    },
    [Language.en]: {
      title: '',
      content: (params) => `Class ${params._class.name}(${params._class.id}) has not attribute ${params.id}`,
      copy: false
    },
  },
  [keyErrors.ClassNotSelectedError]: {
    [Language.ru]: {
      title: '',
      content: () => 'Класс не выбран! Необходимо сначала выбрать класс!',
      copy: false
    },
    [Language.en]: {
      title: '',
      content: () => 'No class has been selected! Please, select class!',
      copy: false
    },
  },
  [keyErrors.NotAuthenticatedError]: {
    [Language.ru]: {
      title: '',
      content: () => 'Необходима аутентификация!',
      copy: false
    },
    [Language.en]: {
      title: '',
      content: () => 'Authentication required!',
      copy: false
    },
  },
  [keyErrors.AuthInvalidCredentialsError]: {
    [Language.ru]: {
      title: '',
      content: () => 'Неверные имя пользователя или пароль!',
      copy: false
    },
    [Language.en]: {
      title: '',
      content: () => 'Wrong username or password!',
      copy: false
    },
  },
  [keyErrors.CacheBuildError]: {
    [Language.ru]: {
      title: '',
      content: () => 'Ошибка построения кэша. Пожалуйста, сообщите администратору.',
      copy: false
    },
    [Language.en]: {
      title: '',
      content: () => 'Cache building error',
      copy: false
    },
  },
  [keyErrors.UnknownServerError]: {
    [Language.ru]: {
      title: 'Ошибка',
      content: 'Произошла непредвиденная ошибка сервера. Пожалуйста, сообщите администратору.',
      copy: false
    },
    [Language.en]: {
      title: 'Error',
      content: 'An unexpected server error occurred. Please, notify the administrator.',
      copy: false
    }
  },
  [keyErrors.NetworkError]: {
    [Language.ru]: {
      title: '',
      content: 'Ошибка сети. Пожалуйста, проверьте наличие доступа к Интернету на вашем устройстве.',
      copy: false
    },
    [Language.en]: {
      title: '',
      content: 'Network error. Please check if your device has access to the Internet.',
      copy: false
    },
  },
  [keyErrors.FeatureNotSupportedError]: {
    [Language.ru]: {
      title: '',
      content: 'К сожалению, эта функция недоступна.',
      copy: false
    },
    [Language.en]: {
      title: '',
      content: 'Sorry, this feature is not available.',
      copy: false
    },
  },
  [keyErrors.RejectedByUserError]: {
    [Language.ru]: {
      title: '',
      content: 'Отклонено пользователем',
      copy: false
    },
    [Language.en]: {
      title: '',
      content: 'Rejected by user',
      copy: false
    },
  },
  [keyErrors.AttributeIsNotApplicableForClassError]: {
    [Language.ru]: {
      title: '',
      content: ({_class, attribute}) => `Атрибут "${attribute}" не может быть применён к классу ${_class}`,
      copy: false
    },
    [Language.en]: {
      title: '',
      content: ({_class, attribute}) => `Attribute "${attribute}" is not applicable to class ${_class}`,
      copy: false
    },
  },
  [keyErrors.ProposedToConfirmError]: {
    [Language.ru]: {
      title: '',
      content: 'Ваши изменения сохранены, но требуют подтверждения модератора',
      copy: false
    },
    [Language.en]: {
      title: '',
      content: 'Your changes are saved, but moderator` confirmation is required',
      copy: false
    },
  },
  [keyErrors.ClassAlreadyExistsError]: {
    [Language.ru]: {
      title: '',
      content: (params) => `Класс c идентификатором "${params.id}" уже существует`,
      copy: false
    },
    [Language.en]: {
      title: '',
      content: (params) => `Class by identifier: "${params.id}" already exists`,
      copy: false
    },
  },
  [keyErrors.AttributeAlreadyExistsError]: {
    [Language.ru]: {
      title: '',
      content: (params) => `Атрибут c идентификатором "${params.id}" уже существует`,
      copy: false
    },
    [Language.en]: {
      title: '',
      content: (params) => `Attribute by identifier: "${params.id}" already exists`,
      copy: false
    },
  },
  [keyErrors.MdmError]: {
    [Language.ru]: {
      title: 'Ошибка MDM',
      content: (params) => params.message,
      copy: true
    },
    [Language.en]: {
      title: 'MDM Error',
      content: (params) => params.message,
      copy: true
    },
  },
  [keyErrors.BackingServiceUnavailableError]: {
    [Language.ru]: {
      title: '',
      content: (params) => `Сервис "${params.serviceName}" недоступен`,
      copy: false
    },
    [Language.en]: {
      title: '',
      content: (params) => `The service with name "${params.serviceName}" is currently unavailable`,
      copy: false
    },
  },
  [keyErrors.SocketError]: {
    [Language.ru]: {
      title: '',
      content: (params) => `Ошибка создания сокета. Тип: ${params.previous && params.previous.name}, сообщение: ${params.previous && params.previous.message}. ${params.connectionId}`, //NOSONAR
      copy: false
    },
    [Language.en]: {
      title: '',
      content: (params) => `Socket creating error. Type: ${params.previous && params.previous.name}, message: ${params.previous && params.previous.message}. ${params.connectionId}`, //NOSONAR
      copy: false
    },
  },
  [keyErrors.WrongImportHeadersError]: {
    [Language.ru]: {
      title: '',
      content: 'Неверные заголовки документа.',
      copy: false
    },
    [Language.en]: {
      title: '',
      content: 'Неверный заголовки документа.',
      copy: false
    },
  },
  [keyErrors.UndefinedMdmError]: {
    [Language.ru]: {
      title: '',
      content: 'Неуточнённая ошибка MDM',
      copy: false
    },
    [Language.en]: {
      title: '',
      content: 'Unknown MDM error',
      copy: false
    },
  },
  [keyErrors.MdmTimeoutError]: {
    [Language.ru]: {
      title: '',
      content: 'Запрос к MDM прекращён по тайм-ауту',
      copy: false
    },
    [Language.en]: {
      title: '',
      content: 'MDM request has terminated by timeout',
      copy: false
    },
  },
  [keyErrors.NoPrefixesError]: {
    [Language.ru]: {
      title: '',
      content: 'Для текущей конечной точки отсутствуют префиксы',
      copy: false
    },
    [Language.en]: {
      title: '',
      content: 'There are no prefixes set for current endpoint',
      copy: false
    },
  },
  [keyErrors.ComparisonVersionError]: {
    [Language.ru]: {
      title: 'Ошибка сравнения версий модели',
      content: mes => mes,
      copy: true
    },
    [Language.en]: {
      title: 'Error comparing model versions',
      content: mes => mes,
      copy: true
    },
  },
  [keyErrors.NoAccessError]: {
    [Language.ru]: {
      title: 'Нет доступа',
      content: mes => mes,
      copy: true
    },
    [Language.en]: {
      title: 'Not access',
      content: mes => mes,
      copy: true
    },
  },
}