<template>
	<div class="notifications-container">
		<div v-for="(notification, idx) in notifications" :key="idx" class="notification-wrapper">
			<Notification
				:title="notification.title"
				:content="notification.content || notification"
				@close="$emit('close', idx)"
			/>
		</div>
		<transition name="bottom">
			<div v-if="isShowOperation" class="status-bar">
				<span class="status" :class="status">
					{{ lang[status] }}
					<span v-if="status === 'error'"> {{ error }}</span>
				</span>
			</div>
		</transition>
	</div>
</template>

<script>
import Notification from './Notification'
import I18n from '@/i18n.mixin'
import { Language } from '@/constants'
import {keyErrors} from "@/notifications";

export default {
	name: 'NotificationContainer',
	components: { Notification },
	mixins: [I18n],
	props: {
		notifications: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {
			isShowOperation: false,
			status: 'pending',
			error: '',
		}
	},
	lang: {
		[Language.en]: {
			pending: 'Operation in progress...',
			error: 'Operation failed.',
			success: 'Operation completed successfully.',
		},
		[Language.ru]: {
			pending: 'Операция выполняется...',
			error: 'Операция провалена.',
			success: 'Операция выполнена успешно.',
		},
	},
	computed: {
		requests() {
			return this.$store.state.asyncOperation.requests
		},
	},
	watch: {
		requests: {
			handler(val) {
				const last = val[val.length - 1].result
				if (last === null) {
					// this.status = 'pending'
					// this.isShowOperation = true
				} else if (last instanceof Error) {
					// this.status = 'error'
					// this.error = last.message
					this.$store.commit('notifications/toast', {id: keyErrors.OperationError, arg: last.message})
				} else {
					// this.status = 'success'
					this.$store.commit('notifications/toast', {id: keyErrors.OperationSuccess})
				}

				if (this.status != 'pending') {
					setTimeout(
						() => {
							if (val.length === this.requests.length) {
								this.isShowOperation = false
								this.error = ''
							}
						},
						this.status === 'success' ? 5000 : 20000
					)
				}
			},
			deep: true,
		},
	},
}
</script>

<style scoped lang="scss">
.notifications-container {
	position: fixed;
	z-index: 3000;
	bottom: 2%;
	right: 2%;
	width: 360px;

	display: flex;
	flex-direction: column;
	align-items: flex-end;

	.notification-wrapper:not(:last-child) {
		margin-bottom: 15px;
	}
}

.status-bar {
	background: black;
	height: 20px;
	width: 100vw;
	position: fixed;
	bottom: 0;
	color: white;
	padding-top: 1px;
	padding-left: 20px;
	font-size: 14px;
	left: 0;
	z-index: 3;

	.status {
		&::before {
			content: '●';
		}
		&.pending {
			&::before {
				color: #fa9732;
			}
		}
		&.error {
			&::before {
				color: #c53c3c;
			}
		}
		&.success {
			&::before {
				color: #6bc700;
			}
		}
	}
}

.bottom-enter-active,
.bottom-leave-active {
	transition: transform 0.2s ease-in;
}
.bottom-enter,
.bottom-leave-to {
	transform: translateY(100%);
}
</style>
