import BaseError from './BaseError'

export default class AttributeAlreadyExistsError extends BaseError<{
	id: string
}> {
	httpCode = 409


	constructor(params: { id: string }) {
		super(params, 'AttributeAlreadyExistsError');
	}

	getMessage(): string {
		return `Attribute: ${this.params.id} already exists!`
	}
}
