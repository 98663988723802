import {ClientSocket as IClientSocket, MessageListener, Events} from '~/shared'
import {SOCKET_BASE_URL} from '@/constants'
import {SocketError} from '@/core/error-handling'
import {v4 as uuid} from 'uuid'

const sockets: {
  withoutClass?: IClientSocket,
  withClass?: IClientSocket
} = {
  withoutClass: null,
  withClass: null
}

export class ClientSocket implements IClientSocket {
  private _wrappedSocket: WebSocket
  private _listeners: Map<keyof Events, MessageListener<keyof Events>[]> = new Map()
  private _byClass: boolean

  constructor(byClass: boolean, socket?: WebSocket) {
    this._byClass = byClass
    if (socket) {
      this.connect(socket)
    }
  }

  public connect(socket: WebSocket): void {
    if (this._wrappedSocket) {
      this._wrappedSocket.close()
    }

    this._wrappedSocket = socket

    console.info('Clientsocket: opened')

    this._wrappedSocket.onmessage = (msg) => {
      const {payload, kind} = JSON.parse(msg.data)
      console.debug('Clientsocket received a message: ' + kind, payload)
      if (this._listeners.has(kind)) {
        // this._listeners.get(kind)(payload)
        for (let listener of this._listeners.get(kind)) {
          listener(payload)
        }
      }
    }
  }

  close(): void {
    this._wrappedSocket.close()
    if (this._byClass) {
      sockets.withClass = null
    }
    else {
      sockets.withoutClass = null
    }
    console.info('Clientsocket: closed')
  }

  subscribe(kind, listener): void {
    if (!this._listeners.has(kind)) this._listeners.set(kind, [])
    this._listeners.get(kind)
        .push(listener)
    // this._listeners.set(kind, listener)
  }

  subscribeAll(listener: (message: Message) => void) {
    this._wrappedSocket.addEventListener('message', (msg) => {
      const message = JSON.parse(msg.data)
      console.info('Socket: message', message)
      listener(message)
    })
  }
}

export const openSocket = (endpoint: string, language?: string, _class?: string) =>
  new Promise<ClientSocket>((resolve, reject) => {
    let url = SOCKET_BASE_URL + '?endpoint=' + endpoint + '&connectionId=' + window._CONNECTION_ID_
    if (language) {
      url += '&language=' + language
    }
    if (_class) {
      url += '&class=' + encodeURIComponent(_class)
    }

    const _ws = _class ? sockets.withClass : sockets.withoutClass
    if (_ws) {
      resolve(_ws)
      return
    }
    const ws = new ClientSocket(_class !== undefined)

    function connect(): void {
      const webSocket = new WebSocket(url)
      const webSocketId = uuid()
      ws.connect(webSocket)
      if (_class) {
        sockets.withClass = ws
      }
      else {
        sockets.withoutClass = ws
      }

      console.log('WS "' + webSocketId + '": created')

      webSocket.onopen = () => {
        resolve(ws)
        ;((retries, timeout) => { //NOSONAR
          console.log('WS "' + webSocketId + '": keep-alive activated')
          let retriesC = 0
          let tid = setInterval(() => {
            if (webSocket.readyState == webSocket.OPEN) {
              webSocket.send('')
              if (retriesC) {
                retriesC = 0
              }
            }
            else {
              if (webSocket.readyState == webSocket.CLOSING || //NOSONAR
                webSocket.readyState == webSocket.CLOSED) {
                if (tid) {
                  clearInterval(tid)
                  tid = null
                }
                console.log('WS "' + webSocketId + '": was closed, keep-alive is stopped')
              }
              else {
                console.log(
                  'WS "' + webSocketId + '": send keep-alive retry ' + retriesC + '/' + retries
                )
                if (retriesC >= retries) {
                  console.log('WS "' + webSocketId + '": keep-alive exceed limits, stopped')
                  if (tid) {
                    clearInterval(tid)
                  }
                  tid = null
                }
                else {
                  retriesC++
                }
              }
            }
          }, timeout)

          return tid
        })(10, 5000)
      }

      webSocket.onerror = (e) => {
        console.log('Websocket error', e)
        reject(
          //@ts-ignore
          new SocketError({
            previous: new Error('Websocket closed with error'),
            connectionId: window._CONNECTION_ID_,
          })
        )
      }
      webSocket.onclose = (e) => {
        console.info('Websocket closed, code: ' + e.code, e)

        //if ungraceful closing - trying to reconnect
        if (e.code == 1006) {
          console.log('Websocket closed ungracefully, try to reconnect...')
          connect()
        }
      }
    }

    connect()
  })

export type Message = {
  [K in keyof Events]: {
    payload: Events[K]
    kind: K
  }
}[keyof Events]
