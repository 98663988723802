import BaseError from './BaseError'

export default class ClassDoesNotExistsError extends BaseError<{ id: string }> {
	httpCode = 404


	constructor(params: { id: string }) {
		super(params, 'ClassDoesNotExistsError');
	}

	getMessage(): string {
		return `Class: ${this.params.id} does not exists!`
	}
}
