import { GetterTree, MutationTree, ActionTree } from 'vuex'
import {apiClient} from "@/api/client";
import {AppConfig} from "~/shared";

class State {
  config: AppConfig = null
}

const getters: GetterTree<State, any> = {
  config: (state) => state.config
}

const mutations: MutationTree<State> = {
  setConfig(state, payload) {
    state.config = payload
  }
}

const actions: ActionTree<State, any> = {
  async init(context) {
    context.commit('setConfig', (await apiClient.get('/config')).data)
  },
}

export default {
  namespaced: true,
  state: new State(),
  mutations: mutations,
  actions: actions,
  getters: getters,
}
