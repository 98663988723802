import BaseError from './BaseError'

export default class NoPrefixesError extends BaseError<{}> {
	httpCode = 500


	constructor(params: {}) {
		super(params, 'NoPrefixesError');
	}

	getMessage(): string {
		return 'There are no prefixes set for current endpoint'
	}
}
