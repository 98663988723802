import BaseError from './BaseError'

export default class CacheBuildError<T extends Error> extends BaseError<{
	previous: T
}> {
	httpCode = 500


	constructor(params: { previous: T }) {
		super(params, 'CacheBuildError');
	}

	getMessage(): string {
		return `Cache build error: ${this.params.previous.name}: ${this.params.previous.message}`
	}
}
