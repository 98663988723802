import { RDFS, AllowedObject, Schema, ARCHIGRAPH } from '~/shared'
import { keys, mergeRight, pick, pipe, prop, sortBy } from 'ramda'

import { createEmptyObject } from '@/utils/form'

export type ClassTreeItem = {
	id: string
	name: string
	children: ClassTreeItem[]
}

export const classCollectionToTree = (classes: any[], rootId: string): ClassTreeItem[] => {
	const intmdt1 = new Map<string, any>(
		classes.map((_class) => [
			_class.id,
			{ id: _class.id, name: _class.name, archive: _class.props[ARCHIGRAPH.archive] },
		])
	)
	const intmdt2 = new Map<string, Set<string>>()
	for (const _class of classes) {
		const parentIds = _class.props[RDFS.subClassOf].map(prop('id'))
		for (const id of parentIds) {
			if (intmdt2.has(id)) {
				intmdt2.get(id).add(_class.id)
			} else {
				intmdt2.set(id, new Set<string>([_class.id]))
			}
		}
	}
	function getItem(id) {
		const _class = intmdt1.get(id)
		const children = intmdt2.has(id) ? Array.from(intmdt2.get(id).values()) : []
		return {
			..._class,
			children: sortBy(prop('name'), children.map(getItem)),
		}
	}
	return sortBy(prop('name'), getItem(rootId).children)
}

export const equalsById =
	<R extends { id: string }, O extends { id: string }>(reference: R) =>
	(object: O) =>
		reference.id === object.id

export const formatDate = (date: Date): string =>
	date
		.toLocaleString('ru-RU', {
			year: 'numeric',
			month: '2-digit',
			day: '2-digit',
			hour: '2-digit',
			minute: '2-digit',
			second: '2-digit',
		})
		.replace(',', '')

export const assertPropsBySchema = <T extends AllowedObject>(schema: Schema<T>) =>
	//@ts-ignore
	pipe<T, T, T>(pick(keys(schema)), mergeRight(createEmptyObject<T>(schema)))
