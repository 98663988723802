import { Api, TypeReference } from '~/shared'

import { apiClient } from '@/api/client'
import { Class, ClassDraft, ClassPatch, ShallowClass } from '~/shared/new-types'

export const getClasses = async (
	endpoint: string,
	language?: string,
	version?: string
): Promise<Api.Response<ShallowClass[]>> => {
	let url = `/endpoints/${endpoint}/classes`
	let query = []
	if (version){
		query.push(`version=${encodeURIComponent(version)}`)
	}
	if (language) {
		query.push(`language=${language}`)
	}
	if (query.length){
		url = `${url}?${query.join('&')}`
	}

	const response = await apiClient.get(url)
	return response.data
}

export const getClass = async (
	endpoint: string,
	id: string,
	language?: string,
	version?: string
): Promise<Api.Response<Class>> => {
	let url = `/endpoints/${endpoint}/classes/${encodeURIComponent(id)}`
	let query = []
	if (version){
		query.push(`version=${encodeURIComponent(version)}`)
	}
	if (language) {
		query.push(`language=${language}`)
	}
	if (query.length){
		url = `${url}?${query.join('&')}`
	}
	const response = await apiClient.get(url)
	return response.data
}

export const getClassesWithMeta = async (
	endpoint: string,
	ids: string[],
	language?: string
): Promise<Api.Response<Class>[]> => {
	let url = '/endpoints/' + endpoint + '/classes/with-meta'
	if (language) {
		url = url + '?language=' + language
	}
	const response = await apiClient.post(url, { ids })
	return response.data
}

export const createClass = async (
	endpoint: string,
	data: ClassDraft,
	language?: string
): Promise<Api.Response<Class>> => {
	let url = '/endpoints/' + endpoint + '/classes'
	if (language) {
		url = url + '?language=' + language
	}
	const response = await apiClient.post(url, data, {
		headers: {
			'content-type': 'application/json',
		},
	})
	return response.data
}

export const patchClass = async (
	endpoint: string,
	id: string,
	data: ClassPatch,
	language?: string
): Promise<Api.Response<Class>> => {
	let url = '/endpoints/' + endpoint + '/classes/' + encodeURIComponent(id)
	if (language) {
		url = url + '?language=' + language
	}
	const response = await apiClient.patch(url, data, {
		headers: {
			'content-type': 'application/json',
		},
	})
	return response.data
}

export const removeClass = async (
	endpoint: string,
	id: string,
	removeIndividuals?: boolean
): Promise<void> => {
	let url = '/endpoints/' + endpoint + '/classes/' + encodeURIComponent(id)
	if (removeIndividuals) {
		url += '?removeIndividuals=1'
	}
	await apiClient.delete(url)
}

export const listClassMentions = async (
	endpoint: string,
	id: string,
	language?: string
): Promise<Api.Response<TypeReference[]>> => {
	let url = '/endpoints/' + endpoint + '/classes/' + encodeURIComponent(id) + '/mentions'
	if (language) {
		url = url + '?language=' + language
	}
	const response = await apiClient.get(url)
	return response.data
}
