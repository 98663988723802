import BaseError from './BaseError'

export default class UndefinedMdmError extends BaseError<{}> {
	httpCode = 500


	constructor(params: {}) {
		super(params, 'UndefinedMdmError');
	}

	getMessage(): string {
		return 'Undefined MDM error'
	}
}
