import {GetterTree, MutationTree, ActionTree} from 'vuex'
import Vue from 'vue'
import Toast from '@/components/Common/Toast'
import notifications from "@/notifications";
import {getUiLang} from "@/utils/lang";

export const toast = (payload: any) => {
  let props

  if (payload.id) {
    let message = notifications[payload.id]
    if (!message) return
    message = message[getUiLang()
      .toLowerCase()]
    if (typeof message.content === 'function') {
      message = {
        ...message,
        content: message.content(payload.arg)
      }
    }
    props = message
  }
  else {
    props = payload
  }

  const content = {
    component: Toast,
    props
  }
  Vue.$toast(content, 'default')
}


class State {
}

const getters: GetterTree<State, any> = {}

const mutations: MutationTree<State> = {
  toast(context, payload) {
    toast(payload)
  }
}

const actions: ActionTree<State, any> = {}

export default {
  namespaced: true,
  state: new State(),
  mutations: mutations,
  actions: actions,
  getters: getters,
}
