import {
	XSD,
	InputType,
	AllowedObject,
	FieldSchema,
	GetFieldValueType,
	isReferenceFieldSchema,
	LiteralFieldSchema,
	Schema,
} from '~/shared'

export const getLiteralFieldType = <T>(field: LiteralFieldSchema<T, XSD>): InputType => {
	if (typeof field.inputType !== 'undefined') {
		return field.inputType
	}
	switch (field.literal) {
		case XSD.boolean:
			return InputType.checkBox
		case XSD.string:
			return InputType.text
		case XSD.double:
		case XSD.integer:
			return InputType.number
		case XSD.date:
			return InputType.date
		case XSD.dateTime:
			return InputType.dateTime
		default:
			return InputType.text
	}
}

export const getLiteralEmptyValue = (type: XSD) => (type === XSD.boolean ? false : null)

export const getEmptyFieldValue = <T extends FieldSchema<any>>(
	fieldSchema: T
): GetFieldValueType<T> | { value: GetFieldValueType<T>; uom: null } => {
	if (fieldSchema.multiple) {
		return [] as GetFieldValueType<T>
	}
	if (isReferenceFieldSchema(fieldSchema)) {
		return null
	} else {
		let v = getLiteralEmptyValue((fieldSchema as LiteralFieldSchema<any, any>).literal) as
			| GetFieldValueType<T>
			| { value: GetFieldValueType<T>; uom: null }
		if (fieldSchema.hasQuantityKind) {
			v = { value: v, uom: null } as { value: GetFieldValueType<T>; uom: null }
		}
		return v
	}
}

export const createEmptyObject = <T extends AllowedObject>(schema: Schema<T>): T => {
	const object: any = {}
	for (const key in schema) {
		if (schema.hasOwnProperty(key)) {
			object[key] = getEmptyFieldValue(schema[key])
		}
	}
	return object
}
