export default abstract class BaseError<P> extends Error {
	name: string
	params: P

	isAppError = true
	httpCode = 500
	message = null

	abstract getMessage(): string

	constructor(params: P, name?: string) {
		super()
		if (!name){
			Object.defineProperty(this, 'name', {
				value: new.target.name,
				enumerable: false,
				configurable: true,
			})
		} else {
			this.name = name
		}

		this.params = params
		if (typeof this.getMessage !== 'undefined') {
			this.message = this.getMessage()
		}
	}
}
