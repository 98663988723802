import BaseError from './BaseError'

export default class ObjectDoesNotExistError extends BaseError<{id: string}> {
	httpCode = 404

	constructor(params:  {id: string} ) {
		super(params, 'ObjectDoesNotExistError');
	}

	getMessage(): string {
		return `Object: ${this.params.id} does not exist!`
	}
}
