import ClassDoesNotExistsError from './ClassDoesNotExistsError'
import ObjectDoesNotExistError from './ObjectDoesNotExist'
import AuthInvalidCredentialsError from './AuthInvalidCredentialsError'
import NotAuthenticatedError from './NotAuthenticatedError'
import CacheBuildError from './CacheBuildError'
import UnknownServerError from './UnknownServerError'
import FeatureNotSupportedError from './feature-not-supported-error'
import AttributeIsNotApplicableForClassError from './AttributeIsNotApplicableForClassError'
import ClassAlreadyExistsError from './class-already-exists-error'
import AttributeAlreadyExistsError from './attribute-already-exists-error'
import ProposedToConfirmError from './proposed-to-confirm-error'
import MdmError from './mdm-error'
import BackingServiceUnavailableError from './backing-service-unavailable-error'
import WrongImportHeadersError from './wrong-import-headers-error'
import UndefinedMdmError from './undefined-mdm-error'
import MdmTimeoutError from './mdm-timeout-error'
import NoPrefixesError from './no-prefixes.error'
import LicenseKeysMismatchError from "./LicenseKeysMismatchError";
import LicenseTechSupportExpireError from "./LicenseTechSupportExpireError";
import LicenseTechSupportDeadlineError from "./LicenseTechSupportDeadlineError";
import LicenseNoResponseKeyError from "./LicenseNoResponseKeyError";
import LicenseInvalidKeyError from "./LicenseInvalidKeyError";
import LicenseInvalidAppIdError from "./LicenseInvalidAppIdError";
import LicenseKeysEqualsError from "./LicenseKeysEqualsError";
import UnknownLicenseError from "./UnkownLicenseError";


export const errorTypes = {
	ClassDoesNotExistsError: (param) => new ClassDoesNotExistsError(param) ,
	ObjectDoesNotExistError: (param) => new ObjectDoesNotExistError(param) ,
	AuthInvalidCredentialsError: (param) => new AuthInvalidCredentialsError(param) ,
	NotAuthenticatedError: (param) => new NotAuthenticatedError(param) ,
	CacheBuildError: (param) => new CacheBuildError(param) ,
	UnknownServerError: (param) => new UnknownServerError(param) ,
	FeatureNotSupportedError: (param) => new FeatureNotSupportedError(param) ,
	AttributeIsNotApplicableForClassError: (param) => new AttributeIsNotApplicableForClassError(param) ,
	ClassAlreadyExistsError: (param) => new ClassAlreadyExistsError(param) ,
	AttributeAlreadyExistsError: (param) => new AttributeAlreadyExistsError(param) ,
	ProposedToConfirmError: (param) => new ProposedToConfirmError(param) ,
	MdmError: (param) => new MdmError(param) ,
	BackingServiceUnavailableError: (param) => new BackingServiceUnavailableError(param) ,
	WrongImportHeadersError: (param) => new WrongImportHeadersError(param) ,
	UndefinedMdmError: (param) => new UndefinedMdmError(param) ,
	MdmTimeoutError: (param) => new MdmTimeoutError(param) ,
	NoPrefixesError: (param) => new NoPrefixesError(param) ,
	LicenseTechSupportExpireError: (param) => new LicenseTechSupportExpireError(param) ,
	LicenseTechSupportDeadlineError: (param) => new LicenseTechSupportDeadlineError(param) ,
	LicenseNoResponseKeyError: (param) => new LicenseNoResponseKeyError(param) ,
	LicenseKeysMismatchError: (param) => new LicenseKeysMismatchError(param) ,
	LicenseInvalidKeyError: (param) => new LicenseInvalidKeyError(param) ,
	LicenseInvalidAppIdError: (param) => new LicenseInvalidAppIdError(param) ,
	LicenseKeysEqualsError: (param) => new LicenseKeysEqualsError(param) ,
	UnknownLicenseError: (param) => new UnknownLicenseError(param)
}