import BaseError from './BaseError'

export default class MdmError extends BaseError<{
	message: string
}> {
	httpCode = 500


	constructor(params: { message: string }) {
		super(params, 'MdmError');
	}

	getMessage(): string {
		return 'MDM Error: ' + this.params.message
	}
}
