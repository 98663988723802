import BaseError from './BaseError'

export default class MdmTimeoutError extends BaseError<{ previous?: Error }> {
	httpCode = 504


	constructor(params: { previous?: Error }) {
		super(params, 'MdmTimeoutError');
	}

	getMessage(): string {
		return (
			'MDM has terminated request by timeout. ' + this.params.previous &&
			this.params.previous.message
		)
	}
}
