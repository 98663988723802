import { GetterTree, MutationTree, ActionTree } from 'vuex'
import {ModelTypes} from "~/shared";
import {get} from "@/api/prefixes";


class State {
  prefixes: ModelTypes.Prefix[] = []
  defaultPrefix: ModelTypes.Prefix = null
}

const getters: GetterTree<State, any> = {
}

const mutations: MutationTree<State> = {
  setPrefixes(state, payload) {
    state.prefixes = payload

    for (let prefix of payload) {
      if (prefix.isDefault) {
        state.defaultPrefix = prefix
        break
      }
    }
  }
}

const actions: ActionTree<State, any> = {
  async init(context) {
    const currentLang = context.rootState.lang.currentLang
    const endpoint = context.rootState.endpoints.endpoint

    context.commit('setPrefixes', await get(endpoint.id, currentLang.id))
  }
}

export default {
  namespaced: true,
  state: new State(),
  mutations: mutations,
  actions: actions,
  getters: getters,
}
