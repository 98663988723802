import BaseError from './BaseError'

export default class ClassAlreadyExistsError extends BaseError<{ id: string }> {
	httpCode = 409


	constructor(params: { id: string }) {
		super(params, 'ClassAlreadyExistsError');
	}

	getMessage(): string {
		return `Class: ${this.params.id} already exists!`
	}
}
