import BaseError from './BaseError'

export default class LicenseKeysEqualsError extends BaseError<{}> {
  httpCode = 500


  constructor(params: {}) {
    super(params, 'LicenseKeysEqualsError');
  }

  getMessage(): string {
    return 'LicenseKeysEquals'
  }
}
