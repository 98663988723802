import {GetterTree, MutationTree, ActionTree} from 'vuex'
import {apiClient} from "@/api/client";
import {PluginCheckout, PluginModule} from "~/shared/plugin-modules.types";

class State {
  hasConfigure: boolean = null
  accessToRepo: boolean = null
  access: boolean = null
  list: PluginModule[] = null
  checkoutResult: Record<string, PluginCheckout> = null
  isBusy: boolean = null
}

const getters: GetterTree<State, any> = {}

const mutations: MutationTree<State> = {
  setConfigure(state, payload) {
    state.hasConfigure = payload.data
  },
  setAccessToRepo(state, payload) {
    state.accessToRepo = payload.data
  },
  setAccess(state, payload) {
    state.access = payload.data
  },
  setList(state, payload) {
    state.list = payload.data
  },
  setCheckout(state, payload) {
    state.checkoutResult = {
      ...(state.checkoutResult ?? {}),
      ...payload.data
    }
  },
  setBusy(state, payload) {
    state.isBusy = payload
  }
}

const actions: ActionTree<State, any> = {
  async init(context) {
    const endpoint = context.rootState.endpoints.endpoint
    context.commit('setConfigure', (await apiClient.get(`endpoints/${endpoint.id}/plugin-modules/configure`)).data)
    if (context.state.hasConfigure)
      context.commit('setAccessToRepo', (await apiClient.get(`endpoints/${endpoint.id}/plugin-modules/access-to-repo`)).data)
    context.commit('setAccess', (await apiClient.get(`endpoints/${endpoint.id}/plugin-modules/access`)).data)
  },
  async getModules(context) {
    context.commit('setBusy', true)
    const currentLang = context.rootState.lang.currentLang
    context.commit('setList', (await apiClient.get(`plugin-modules?lang=${currentLang.id}`)).data)
    context.dispatch('checkByList', context.state.list.map(el => el.path))
    context.commit('setBusy', false)
  },
  async checkByList(context, payload) {
    context.commit('setBusy', true)
    const currentLang = context.rootState.lang.currentLang
    const endpoint = context.rootState.endpoints.endpoint
    context.commit('setCheckout', (await apiClient.post(`endpoints/${endpoint.id}/plugin-modules/check?lang=${currentLang.id}`, payload)).data)
    context.commit('setBusy', false)
  }
}

export default {
  namespaced: true,
  state: new State(),
  mutations: mutations,
  actions: actions,
  getters: getters,
}
