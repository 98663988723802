import BaseError from './BaseError'

export default class NotAuthenticatedError extends BaseError<void> {
	httpCode = 401


	constructor(params: void) {
		super(params, 'NotAuthenticatedError');
	}

	getMessage(): string {
		return 'Not authenticated!'
	}
}
