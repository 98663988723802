import {GetterTree, MutationTree, ActionTree} from 'vuex'
import {Language} from "~/shared";
import {getList as getLanguages} from "@/api/languages";

const LANGUAGE_LOCAL_STORAGE_KEY = 'agmir:contentLanguage'

const getStoredLanguage = (): Language => {
  const raw = window.localStorage.getItem('agmir_' + LANGUAGE_LOCAL_STORAGE_KEY)
  return typeof raw === 'string' ? JSON.parse(raw) : null
}

class State {
  langList: Language[] = []
  currentLang: Language = null
  defaultLang: Language = null
}

const getters: GetterTree<State, any> = {}

const mutations: MutationTree<State> = {
  setList(state, payload) {
    state.langList = payload
    state.defaultLang = payload.find((item) => item.isDefault)
  },
  select(state, payload: string) {
    const language = state.langList.find((item) => item.id === payload)
    if (!language) {
      throw new Error('no lang')
    }
    state.currentLang = language
    window.localStorage.setItem('agmir_' + LANGUAGE_LOCAL_STORAGE_KEY, JSON.stringify(language))
  }
}

const actions: ActionTree<State, any> = {
  async init(context) {
    try {
      context.commit('setList', await getLanguages(context.rootState.endpoints.endpoint.id))

      if (!context.state.currentLang) {
        const storedLanguage = getStoredLanguage()

        if (storedLanguage && context.state.langList.find((item) => item.id === storedLanguage.id)) {
          context.commit('select', storedLanguage.id)
        }
        else {
          context.commit('select', context.state.defaultLang.id)
        }
      }
      if (!context.state.langList.find((item) => item.id === context.state.currentLang.id)) {
        context.commit('select', context.state.defaultLang.id)
      }
    } catch (e) {
      console.error(e)
      context.commit('notifications/toast', {id: e.name, arg: e.message})
    }
  }
}

export default {
  namespaced: true,
  state: new State(),
  mutations: mutations,
  actions: actions,
  getters: getters,
}
