import BaseError from './BaseError'

export default class LicenseTechSupportDeadlineError extends BaseError<{}> {
  httpCode = 500

  constructor(params: {}) {
    super(params, 'LicenseTechSupportDeadlineError');
  }

  getMessage(): string {
    return 'LicenseTechSupportDeadline'
  }
}
